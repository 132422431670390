import React from 'react'
import ReduxToastr from 'react-redux-toastr'

import AppRoutes from './AppRoutes'

import './scss/styles.scss'

const MainApp = () => (
    [
        <AppRoutes
            key='app-routes'
        />,
        <ReduxToastr
            closeOnToastrClick
            confirmOptions={{
                cancelText: 'Cancelar',
                okText: 'Aceptar',
            }}
            key='app-tostr'
            newestOnTop={false}
            preventDuplicates
            progressBar
            position='top-right'    // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
            timeOut={12000}
            transitionIn='fadeIn'   //bounceIn, bounceInDown, fadeIn
            transitionOut='fadeOut' //bounceOut, bounceOutUp, fadeOut
        />
    ]
)

export default MainApp