import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'

import { reducer as authReducer } from './Auth'
import { reducer as sessionReducer } from './Session'

export default combineReducers({
    auth: authReducer,
    form: formReducer,
    session: sessionReducer,
    toastr: toastrReducer
})