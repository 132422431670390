
import React, { Component } from 'react'

import { setDocumentTitle } from './utils'

const withTitle = (ComponentWrapper, title) =>

    class WrappedComponent extends Component {

        componentDidMount() {
            setDocumentTitle({ title })
        }

        render() {
            return (
                <div className='animated fadeIn'>
                    <ComponentWrapper
                        {...this.props}
                    />
                </div>
            )
        }
    }

export default withTitle