import axios from 'axios'

import {
    SET_AUTHENTICATED,
    SET_AUTH_FEEDBACK,
    SET_AUTH_USER,
} from '../reducers/actions'

export const setAuthenticated = (authenticated = false, loggedOut = false) => ({
    type: SET_AUTHENTICATED,
    authenticated,
    loggedOut
})

export const setAuthFeedback = (message = null, error = false) => ({
    type: SET_AUTH_FEEDBACK,
    error,
    message,
})

export const setAuthUser = (user = null) => ({
    type: SET_AUTH_USER,
    user,
})

export const authenticate = (credentials) => axios.post('/api/admin/login', credentials)

export const logout = () => axios.post('/api/admin/logout')

export const userInfo = () => axios.post('/api/admin/me')

export const registerAuthHeaders = () => (dispatch) => {
    axios.interceptors.request.use((config) => {
        const authHeaderKey = 'Authorization'
        try {
            const token = getAuthToken()
            if (token)
                config.headers[authHeaderKey] = `Bearer ${token}`
            else
                delete config.headers[authHeaderKey];
        } catch (error) {
            // Storage cannot be read
        }
        return config;
    }, (error) => Promise.reject(error))

    axios.interceptors.response.use((response) => response, (error) => {
        if (error && error.status === 401) {
            dispatch(setAuthFeedback(error.message || 'No autorizado', true))
            dispatch(doLogout())
        }
        return Promise.reject(error)
    })
}

export const getAuthToken = () => localStorage.getItem('session')

export const setAuthToken = (token) => localStorage.setItem('session', token)

export const clearLocalStorage = () => localStorage.clear()

export const doLogout = () => (dispatch) => {
    dispatch(setAuthenticated(false, true))
    dispatch(setAuthUser())

    try {
        clearLocalStorage()
    } catch (error) {
        // Error reading storage
    }
} 