import {
    SET_ERROR_UI,
    SET_LOADING_UI,
} from './actions'

export const reducer = (state = {
    errorUI: null,
    loadingUI: false,
}, action = {}) => {
    switch (action.type) {
        case SET_ERROR_UI:
            return { ...state, errorUI: action.error, loadingUI: false, }
        case SET_LOADING_UI:
            return { ...state, errorUI: null, loadingUI: action.loading }
        default:
            return state
    }
}