import React, { Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import Authenticated from './components/Session'
import ErrorBoundary from './components/App/Error'
import withTitle from './components/Wrappers/withTitle'

const loading = () =>
    <div className='animated fadeIn d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold h5 py-3 mb-0'>
        <div className='w-50 mx-auto'>Cargando, por favor espere...</div>
    </div>

const Login = React.lazy(() => import('./pages/Auth/Login'))

const ProtectedRoutes = React.lazy(() => import('./ProtectedRoutes'))

const AppRoutes = () => (
    <BrowserRouter>
        <ErrorBoundary>
            <Suspense fallback={loading()}>
                <Switch>
                    {/* Public Routes - without authentication - */}
                    <Authenticated path='/login' exact component={withTitle(Login, 'Iniciar Sesión')} />

                    {/* Protected Routes - login required - */}
                    <Authenticated
                        authenticated
                        component={ProtectedRoutes}
                        path='/'
                    />
                </Switch>
            </Suspense>
        </ErrorBoundary>
    </BrowserRouter>
)

export default AppRoutes