import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import Loading from './Loading'
import Error from './Error'

const Authenticated = ({
	authenticated: needsAuthentication,
	component: Component,
	error,
	isAuthenticated,
	loading,
	loggedOut,
	...rest
}) => (
		<Route
			{...rest}
			render={
				(props) => {
					const { location } = props

					if (needsAuthentication && !isAuthenticated)
						return <Redirect
							to={{
								pathname: '/login',
								state: loggedOut ? {} : { from: location }
							}}
						/>
					if (!needsAuthentication && isAuthenticated) {
						const nextUrl = location.state && location.state.from ?
							location.state.from :
							{
								pathname: '/',
								search: ''
							}
						return <Redirect
							to={{
								pathname: nextUrl.pathname,
								search: nextUrl.search,
							}}
						/>
					}

					if (isAuthenticated && error)
						return <Error error={error} />
					if (isAuthenticated && loading)
						return <Loading />
					return (
						<Component {...props} />
					)
				}
			}
		/>
	)

Authenticated.propTypes = {
	authenticated: PropTypes.bool,
}

Authenticated.defaultProps = {
	authenticated: false
}

export default connect(({ auth, session }) => ({
	error: session.errorUI,
	isAuthenticated: auth.authenticated,
	loading: session.loadingUI,
	loggedOut: auth.loggedOut,
}))(Authenticated)