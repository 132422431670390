import React from 'react'
import { Container, Button } from 'reactstrap'

const ErrorUI = ({ error }) => (
	<div className='bg-white'>
		<Container className='min-vh-100 d-flex flex-column align-items-center justify-content-center'>
			<div className='text-center mb-4'>
				<h3>Lo sentimos...</h3>
				<h6>{error.message}</h6>
			</div>
			<i className='far fa-frown-open fa-fw fa-10x text-danger'></i>
			<div className='text-center mt-3'>
				<h6>Si el problema persiste, comuníquese con el proveedor del servicio.</h6>
				<Button
					className='mt-3'
					color='secondary'
					onClick={() => window.location.reload()}
				>
					Recargar
            </Button>
			</div>
		</Container>
	</div>
)

export default ErrorUI