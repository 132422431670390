import {
    SET_ERROR_UI,
    SET_LOADING_UI,
} from '../reducers/actions'

export const setErrorUI = (error = null) => ({
    type: SET_ERROR_UI,
    error,
})

export const setLoadingUI = (loading = false) => ({
    type: SET_LOADING_UI,
    loading,
})