import {
    SET_AUTHENTICATED,
    SET_AUTH_FEEDBACK,
    SET_AUTH_USER,
} from './actions'

export const reducer = (state = {
    authenticated: false,
    error: false,
    loggedOut: false,
    message: null,
    user: null,
}, action = {}) => {
    switch (action.type) {
        case SET_AUTHENTICATED:
            return { ...state, authenticated: action.authenticated, loggedOut: action.loggedOut }
        case SET_AUTH_FEEDBACK:
            return { ...state, error: action.error, message: action.message }
        case SET_AUTH_USER:
            return { ...state, user: action.user }
        default:
            return state
    }
}