import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import registerMainInterceptor from './registerMainInterceptor'
import reducers from './reducers'
import {
    getAuthToken,
    registerAuthHeaders,
    setAuthenticated,
    setAuthFeedback,
    setAuthUser,
    userInfo,
} from './actions/Auth'
import {
    setErrorUI,
    setLoadingUI,
} from './actions/Session'

registerMainInterceptor()

let middleWare = applyMiddleware(thunk)

// Config for reduxTools
if (process.env.NODE_ENV !== 'production') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    middleWare = composeEnhancers(middleWare)
}

export const store = createStore(reducers, middleWare)


// Auth Token for stateless authentication
store.dispatch(registerAuthHeaders())

try {
    if (getAuthToken()) {

        store.dispatch(setLoadingUI(true))
        store.dispatch(setAuthenticated(true))

        userInfo().then(userInfo => {
            if (!userInfo)
                store.dispatch(setErrorUI({ error: 'No hubo respuesta correcta' }))
            else {
                store.dispatch(setAuthUser(userInfo))
                store.dispatch(setLoadingUI())

            }
        }).catch(error =>
            store.dispatch(setErrorUI(error))
        )
    }
} catch (error) {
    store.dispatch(setAuthFeedback('Debe tener las cookies del navegador habilitadas', true))
}