import PropTypes from 'prop-types'

/* 
    Loaders proptypes 
*/
const proptypes = {
    color: PropTypes.string,
    loading: PropTypes.bool,
    size: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
}

export default proptypes