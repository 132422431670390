import React, { Component } from 'react'

import Alert from '../Alert'

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service

    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Alert
                    message={
                        <div>
                            Ha ocurrido un error.
                            <a
                                className='text-danger ml-2'
                                href='/'
                                onClick={(e) => {
                                    e.preventDefault()
                                    window.location.reload()
                                }}
                            >
                                <i className='fas fa-redo-alt fa-fw mr-1' />
                                Recargar página
                            </a>.
                        </div>
                    }
                />
            )
        }

        return this.props.children;
    }
}