import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'

const CustomAlert = ({
    className,
    closeClassName,
    color,
    isOpen,
    message,
    title,
    toggle,
}) => {

    let icon;
    if (color === 'success') icon = 'fa-check-circle'
    else if (color === 'warning') icon = 'fa-exclamation-triangle'
    else if (color === 'info') icon = 'fa-info-circle'
    else if (color === 'danger') icon = 'fa-exclamation-circle'
    else icon = 'fa-frown'

    return (
        <Alert
            className={className}
            closeClassName={closeClassName}
            color={color}
            isOpen={isOpen}
            toggle={toggle}
        >
            <div className='d-flex'>
                <div className='mr-3'>
                    <i className={`fas ${icon} fa-fw fa-3x`}></i>
                </div>
                <div>
                    <div className='text-left font-weight-bold'>{title}</div>
                    <div className='text-left'>{message}</div>
                </div>
            </div>
        </Alert>
    )
}

CustomAlert.propTypes = {
    className: PropTypes.string,
    closeClassName: PropTypes.string,
    color: PropTypes.oneOf([
        'danger',
        'dark',
        'info',
        'light',
        'primary',
        'secondary',
        'success',
        'warning',
    ]),
    isOpen: PropTypes.bool,
    message: PropTypes.node,
    title: PropTypes.string,
    toggle: PropTypes.func,
}

CustomAlert.defaultProps = {
    color: 'danger',
    isOpen: true,
    title: '¡Lo sentimos!',
    message: 'Ha ocurrido un error',
}

export default CustomAlert