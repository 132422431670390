import React from 'react'
import { Container } from 'reactstrap'

import Bounce from '../Loader/Bounce'

const LoadingUI = () => (
    <div className='bg-white'>
        <Container className='min-vh-100 d-flex flex-column align-items-center justify-content-center'>
            <img
                alt={process.env.REACT_APP_NAME}
                className='img-fluid my-2'
                src='/images/logo-short-color.png'
                style={{ maxWidth: 150 }}
            />
            <div className='text-center mb-4'>
                <h3>Cargando ...</h3>
                <h6>Espere un momento, por favor</h6>
            </div>
            <Bounce size={55} />
        </Container>
    </div>
)

export default LoadingUI