import React from 'react'
import {
    BounceLoader
} from 'halogenium'

import { defaultProps } from './defaults'
import proptypes from './proptypes'

const Bounce = (props) => (
    <BounceLoader
        {...props}
    />
)

Bounce.propTypes = proptypes

Bounce.defaultProps = defaultProps

export default Bounce